/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import GlobalQuestions from "./GlobalQuestions/GlobalQuestions";
import CustomQuestionSets from "./CustomQuestionSets/CustomQuestionSets";

const Questions = ({
  selectedTab,
  setSelectedTab,
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  useEffect(() => {
    setSelectedTab("all questions");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <nav className="flex flex-wrap items-center">
        {["all questions", "question sets"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize ${
                selectedTab === tab
                  ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {selectedTab === "all questions" ? (
        <GlobalQuestions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : (
        <CustomQuestionSets />
      )}
    </section>
  );
};

export default Questions;

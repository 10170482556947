import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { KeyStringVal } from "src/types/general";
import { attributeColors } from "src/constants/general";
import Response from "./Questions/GlobalQuestions/Response";
import TableLayout from "../../layouts/TableLayout";
import {
  convertToUTCShortString,
  handleSort,
  lastUpdatedAt,
  sortRows,
} from "../../utils/general";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "../../components/General/TablePagination";
import { initialSort, pageSize } from "../../constants/general";
import { Sort } from "../../types/dashboard";

const SearchResults = ({
  searchData,
  selectedQuestions,
  setSelectedQuestions,
  selectedTab,
}: {
  searchData: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
  selectedTab: string;
}) => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const type = selectedTab;
  const results = searchData && searchData[type];
  const resultCount = results?.data?.length || results?.length || 0;

  const metadata =
    results?.header?.metadata && JSON.parse(results.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = results?.data && sortRows(results.data, sort);

  const totalCount = results?.pager?.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-5 w-full min-h-full">
      <span>
        {resultCount} result{resultCount !== 1 && "s"} found
      </span>
      {resultCount > 0 ? (
        <section className="flex flex-col flex-grow gap-5 w-full min-h-full">
          {type === "all questions" ? (
            <ul className="flex flex-col flex-grow gap-5">
              {sortedRows?.map((qa: KeyStringVal, index: number) => {
                return (
                  <Response
                    key={index}
                    qa={qa}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                  />
                );
              })}
            </ul>
          ) : (
            <TableLayout fullHeight>
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {filteredMetadata?.map((col: string) => {
                    const column = metadata.properties[col];
                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <h4 className="break-all">{column.title}</h4>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                {sortedRows?.map((row: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      onClick={() => {
                        if (type === "assessment cycles") {
                          sessionStorage.GRCCategory = "assessments";
                          navigate(
                            `/third-party-risk/assessment-cycles/details?assessment_id=${row.assessment_id}`
                          );
                        } else {
                          sessionStorage.GRCCategory = "vendors";
                          navigate(
                            `/third-party-risk/vendors/details?vendor_id=${row.third_party_id}`
                          );
                        }
                      }}
                    >
                      {filteredMetadata?.map(
                        (col: string, colIndex: number) => {
                          return (
                            <td
                              key={`${index}-${colIndex}`}
                              className="relative px-6 py-3 last:pr-16 text-left break-all"
                            >
                              <p
                                className={`${
                                  attributeColors[
                                    String(row[col]).toLowerCase()
                                  ]
                                }`}
                              >
                                {col === "last_updated"
                                  ? lastUpdatedAt(row[col])
                                  : col.includes("date")
                                  ? convertToUTCShortString(row[col])
                                  : String(row[col])}
                              </p>
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </TableLayout>
          )}
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : null}
    </section>
  );
};

export default SearchResults;

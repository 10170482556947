/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GetVendorDocuments } from "../../../../../../services/third-party-risk/vendors/vendors";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../../../components/Loader/Loader";
import NewDocuments from "./NewDocuments";
import { Disclosure } from "@headlessui/react";
import VendorResponseList from "./VendorResponseList/VendorResponseList";
import DeleteDocument from "./DeleteDocument";
import AuditReportList from "../AuditReportList/AuditReportList";
import PrivacyDocumentList from "../PrivacyDocumentList/PrivacyDocumentList";

const DocumentList = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");

  const { data: documentList, status: documentListStatus } = GetVendorDocuments(
    vendorID,
    assessmentID
  );

  const documentTypes = [
    ...new Set(documentList?.reduce((pV, cV) => [...pV, cV.document_type], [])),
  ] as string[];

  const filteredDocumentList =
    selectedDocumentType !== ""
      ? documentList?.filter(
          (document) => document.document_type === selectedDocumentType
        )
      : [];

  useEffect(() => {
    if (documentTypes?.length > 0 && selectedDocumentType === "")
      setSelectedDocumentType(documentTypes[0]);
  }, [documentTypes]);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <section className="flex flex-row flex-grow gap-10 md:gap-20 w-full overflow-auto scrollbar">
        {documentTypes?.length > 0 && (
          <nav className="flex flex-row flex-grow pb-2 overflow-auto scrollbar">
            {[...documentTypes].map((documentType) => {
              return (
                <button
                  key={documentType}
                  className={`px-8 py-2 text-center capitalize border-b-2 ${
                    selectedDocumentType === documentType
                      ? "dark:text-white dark:border-signin"
                      : "dark:text-checkbox dark:hover:text-white dark:border-checkbox"
                  }`}
                  onClick={() => setSelectedDocumentType(documentType)}
                >
                  {documentType}
                </button>
              );
            })}
          </nav>
        )}
        {documentList?.length > 0 && (
          <NewDocuments vendorID={vendorID} assessmentID={assessmentID} />
        )}
      </section>
      {documentListStatus === "loading" ? (
        <Loader />
      ) : selectedDocumentType === "Audit Reports" ? (
        <AuditReportList vendorID={vendorID} assessmentID={assessmentID} />
      ) : selectedDocumentType === "Privacy Documents" ? (
        <PrivacyDocumentList vendorID={vendorID} assessmentID={assessmentID} />
      ) : filteredDocumentList?.length > 0 ? (
        <ul className="flex flex-col flex-grow gap-5">
          {filteredDocumentList?.map((curDoc, documentIndex) => {
            const fileType = curDoc.download_uri.slice(
              curDoc.download_uri.lastIndexOf(".") + 1,
              curDoc.download_uri.indexOf("?")
            );

            return (
              <li
                key={documentIndex}
                className="group flex flex-col flex-grow gap-2 p-3 bg-gradient-to-r dark:from-checkbox/70 dark:to-white/10 rounded-md"
              >
                <header className="flex items-center justify-between gap-10">
                  <h4>{curDoc.filename}</h4>
                  <article className="flex items-center gap-5">
                    <DeleteDocument
                      vendorID={vendorID}
                      assessmentID={assessmentID}
                      documentID={curDoc.document_id}
                    />
                    <button
                      className="dark:hover:text-signin/60 duration-100"
                      onClick={() => {
                        let a = document.createElement("a");
                        a.href = curDoc.download_uri;
                        a.target = "_blank";
                        a.click();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="text-signin"
                      />{" "}
                      Download
                    </button>
                  </article>
                </header>
                {selectedDocumentType === "Questionnaire Responses" ? (
                  <VendorResponseList
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                    documentID={curDoc.document_id}
                  />
                ) : (
                  <Disclosure>
                    {({ open }) => (
                      <section className="grid content-start gap-3">
                        <Disclosure.Button className="flex items-center gap-2 w-max text-sm">
                          <FontAwesomeIcon
                            icon={
                              open ? faChevronCircleDown : faChevronCircleRight
                            }
                            className="dark:text-black"
                          />
                          <p>{open ? "Hide" : "Show"} File</p>
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <DocViewer
                            documents={[
                              {
                                uri: curDoc.download_uri,
                                fileType: fileType,
                              },
                            ]}
                            pluginRenderers={DocViewerRenderers}
                            config={{
                              header: {
                                disableHeader: false,
                                disableFileName: true,
                                retainURLParams: false,
                              },
                            }}
                          />
                        </Disclosure.Panel>
                      </section>
                    )}
                  </Disclosure>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/policies-placeholder.svg"
            alt="reviews placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Documents</h4>
            <h4>No documents available</h4>
            <NewDocuments vendorID={vendorID} assessmentID={assessmentID} />
          </article>
        </section>
      )}
    </section>
  );
};

export default DocumentList;

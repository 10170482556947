import React, { useEffect, useState } from "react";
import AskAQuestion from "./AskAQuestion";
import SelectQuestionnairesFilter from "src/components/Filter/AuditsAssessments/SelectQuestionnaireFilter";
import ResponseList from "./ResponseList";
import { KeyStringVal } from "../../../../../../types/general";
import DraftList from "./DraftList/DraftList";
import UploadQuestionnaire from "./UploadQuestionnaire";

const QnA = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const [nav, setNav] = useState<number>(1);
  const [inputs, setInputs] = useState<any>({ drafts: [] });
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<KeyStringVal>({ generated_id: "", document_name: "" });

  useEffect(() => {
    if (nav === 2) setTimeout(() => setNav(1), 5000);
  }, [nav]);

  return (
    <section className="flex flex-col flex-grow gap-5">
      {nav === 1 ? (
        <section className="grid gap-12">
          <section className="flex items-center justify-between gap-10 mx-auto">
            <SelectQuestionnairesFilter
              selectedQuestionnaire={selectedQuestionnaire}
              setSelectedQuestionnaire={setSelectedQuestionnaire}
            />
            <span>or</span>
            <UploadQuestionnaire
              vendorID={vendorID}
              assessmentID={assessmentID}
            />
            <span>or</span>
            <AskAQuestion inputs={inputs} setInputs={setInputs} />
          </section>
          <DraftList
            vendorID={vendorID}
            assessmentID={assessmentID}
            inputs={inputs}
            setInputs={setInputs}
            setNav={setNav}
            selectedQuestionnaire={selectedQuestionnaire}
            setSelectedQuestionnaire={setSelectedQuestionnaire}
          />
        </section>
      ) : (
        <section className="grid content-start gap-5">
          <article className="grid gap-3 text-center">
            <h4 className="text-xl">
              Uno is processing your questions. We will notify you when ready!
            </h4>
            <img
              src="/grc/draft-questions-processing.png"
              alt="questions processing"
              className="h-[20rem] mx-auto"
            />
          </article>
          <button
            className="px-4 py-2 mx-auto h-max dark:hover:bg-signin/30 duration-100 dark:bg-signin rounded-md"
            onClick={() => setNav(1)}
          >
            Ask another
          </button>
        </section>
      )}
      <ResponseList vendorID={vendorID} assessmentID={assessmentID} />
    </section>
  );
};

export default QnA;

import {
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { KeyStringVal } from "src/types/general";
import Source from "./Source";
import CopyToClipboard from "src/components/General/CopyToClipboard";
import { EditAnswer } from "src/services/questionnaire-rfp";
import Notes from "./Notes";

const Response = ({
  vendorID,
  assessmentID,
  questionIndex,
  questionRef,
  question,
  selectedExportQuestions,
  setSelectedExportQuestions,
}: {
  vendorID: string;
  assessmentID: string;
  questionIndex: number;
  questionRef: any;
  question: any;
  selectedExportQuestions: any;
  setSelectedExportQuestions: any;
}) => {
  const questionID = question.question_id;

  const [editedAnswer, setEditedAnswer] = useState<string>(question.answer);

  const editAnswer = EditAnswer(assessmentID);

  const handleDiscardEditedAnswer = () => setEditedAnswer(question.answer);

  return (
    <li
      key={questionIndex}
      ref={(el) => {
        if (questionRef && questionRef.current)
          questionRef.current[questionIndex] = el;
      }}
      className="grid"
    >
      <Disclosure key={questionIndex}>
        {({ open }) => {
          return (
            <section className="grid gap-2">
              <header className="flex items-start gap-2 text-base w-3/5">
                <h4 className="text-left">Q: {question.question}</h4>
                <Disclosure.Button>
                  <FontAwesomeIcon
                    icon={open ? faChevronCircleDown : faChevronCircleRight}
                    className="dark:text-checkbox"
                  />
                </Disclosure.Button>
              </header>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <section className="grid gap-3 p-4 bg-gradient-to-br dark:from-checkbox/30 dark:to-white/10 rounded-md">
                    <Notes
                      vendorID={vendorID}
                      assessmentID={assessmentID}
                      questionID={questionID}
                    />
                    <article className="flex gap-2 text-base p-3 break-words dark:bg-black/60 rounded-md">
                      <article className="w-max">
                        <CopyToClipboard copiedValue={question.answer} />
                      </article>
                      <article className="grid content-start gap-3 w-full">
                        {question.answer !== "" ? (
                          <article className="flex items-start gap-2 w-full">
                            A:{" "}
                            <textarea
                              value={editedAnswer}
                              onChange={(e) => setEditedAnswer(e.target.value)}
                              className={`px-4 py-1 pb-10 w-full ${
                                editedAnswer?.length >= 500 ? "h-[15rem]" : ""
                              } bg-gradient-to-b dark:bg-main border-t border-b dark:border-know/60 know focus:outline-none resize-none overflow-auto scrollbar`}
                            />
                          </article>
                        ) : (
                          "Question is currently being processed"
                        )}
                        {question.answer !== editedAnswer && (
                          <article className="flex items-center justify-self-end gap-2">
                            <button
                              className="p-3 py-1 dark:bg-reset dark:hover:bg-reset/60 duration-100 rounded-md"
                              onClick={handleDiscardEditedAnswer}
                            >
                              Discard
                            </button>
                            <button
                              className="px-3 py-1 dark:bg-no dark:hover:bg-no/60 duration-100 rounded-md"
                              onClick={() => {
                                editAnswer.mutate({
                                  questionID: questionID,
                                  answer: editedAnswer,
                                });
                                handleDiscardEditedAnswer();
                              }}
                            >
                              Save
                            </button>
                          </article>
                        )}
                      </article>
                    </article>
                    {question.context_info?.length > 0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2 text-sm">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4 className="text-lg">Sources</h4>
                                  <FontAwesomeIcon
                                    icon={
                                      open
                                        ? faChevronCircleDown
                                        : faChevronCircleRight
                                    }
                                    className="dark:text-black"
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.context_info.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              documentType="policies"
                                              sourceIndex={sourceIndex}
                                              source={source}
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                    {question.mapped_questions?.from_assessments?.length >
                      0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2 text-sm">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4 className="text-lg">From Assessments</h4>
                                  <FontAwesomeIcon
                                    icon={
                                      open
                                        ? faChevronCircleDown
                                        : faChevronCircleRight
                                    }
                                    className="dark:text-black"
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.mapped_questions?.from_assessments?.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              documentType="policies"
                                              sourceIndex={sourceIndex}
                                              source={source}
                                              questionID={questionID}
                                              selectedExportQuestions={
                                                selectedExportQuestions
                                              }
                                              setSelectedExportQuestions={
                                                setSelectedExportQuestions
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                    {question.mapped_questions?.from_question_banks?.length >
                      0 && (
                      <section className="grid gap-2">
                        <Disclosure>
                          {({ open }) => {
                            return (
                              <section className="grid gap-2 text-sm">
                                <Disclosure.Button className="flex items-center gap-2 w-max">
                                  <h4 className="text-lg">
                                    From Question Banks
                                  </h4>
                                  <FontAwesomeIcon
                                    icon={
                                      open
                                        ? faChevronCircleDown
                                        : faChevronCircleRight
                                    }
                                    className="dark:text-black"
                                  />
                                </Disclosure.Button>
                                <Transition
                                  show={open}
                                  enter="transition duration-100 ease-out"
                                  enterFrom="transform scale-95 opacity-0"
                                  enterTo="transform scale-100 opacity-100"
                                  leave="transition duration-75 ease-out"
                                  leaveFrom="transform scale-100 opacity-100"
                                  leaveTo="transform scale-95 opacity-0"
                                >
                                  <Disclosure.Panel>
                                    <section className="flex flex-wrap items-center gap-5">
                                      {question.mapped_questions?.from_question_banks?.map(
                                        (
                                          source: KeyStringVal,
                                          sourceIndex: number
                                        ) => {
                                          return (
                                            <Source
                                              key={sourceIndex}
                                              documentType="policies"
                                              sourceIndex={sourceIndex}
                                              source={source}
                                              questionID={questionID}
                                              selectedExportQuestions={
                                                selectedExportQuestions
                                              }
                                              setSelectedExportQuestions={
                                                setSelectedExportQuestions
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </section>
                                  </Disclosure.Panel>
                                </Transition>
                              </section>
                            );
                          }}
                        </Disclosure>
                      </section>
                    )}
                  </section>
                </Disclosure.Panel>
              </Transition>
            </section>
          );
        }}
      </Disclosure>
    </li>
  );
};

export default Response;

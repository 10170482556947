import { GetAgreementProposalReviewMetadata } from "src/services/agreement-proposal-review";
import { convertToUTCShortString } from "src/utils/general";

const AgreementProposalReviewMetadata = ({
  documentID,
}: {
  documentID: string;
}) => {
  const { data: metadata } = GetAgreementProposalReviewMetadata(documentID);

  return (
    <>
      {metadata && (
        <header className="grid content-start">
          <header className="flex flex-wrap items-center gap-5 break-words cursor-pointer text-left dark:text-white">
            <h4 className="text-xl font-medium">{metadata.agreement_name}</h4>
            <article className="flex items-center gap-2 px-3 py-1 text-xs dark:bg-review rounded-sm">
              <p className="dark:text-black">
                {metadata.counts} items to review
              </p>
              <span className="dark:text-date">
                {convertToUTCShortString(Number(metadata.agreement_date))}
              </span>
            </article>
          </header>
          {metadata.tags?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2">
              <span>Tags</span>
              {metadata.tags.map((tag: string, index: number) => {
                return (
                  <span key={index} className="px-4 dark:bg-org rounded-full">
                    {tag}
                  </span>
                );
              })}
            </article>
          )}
        </header>
      )}
    </>
  );
};

export default AgreementProposalReviewMetadata;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import "./style/AreaHighlight.css";
import "./style/StrikeThrough.css";
import Loader from "src/components/Loader/Loader";
import { GetAgreement } from "src/services/agreement-proposal-review";
import { GetDocumentStatus } from "src/services/grc";
import { GetPdfPreview } from "src/services/regulation-policy/circular";
import ReviewItems from "./ReviewItems";

const AgreementReview = ({ documentID }: { documentID: string }) => {
  const [selectedHighlight, setSelectedHighlight] = useState("");

  const pdfHighlighter = useRef<any>(null);

  const documentType = "contractual_agreements";

  const { data: documentStatus } = GetDocumentStatus(documentType, documentID);
  const { data: pdfPreview } = GetPdfPreview(documentID, "", documentType);
  const { data: agreement } = GetAgreement(documentID);

  const url = pdfPreview?.bucket_url;
  const reviewItemList = agreement?.data?.reduce(
    (pCat: string[], cCat: any) => [
      ...pCat,
      ...cCat.sub_categories.reduce(
        (pQ: any, cQ: any) => [
          ...pQ,
          ...cQ.sources.reduce(
            (pSource: any, cSource: any) => [...pSource, cSource],
            []
          ),
        ],
        []
      ),
    ],
    []
  ) as any[];
  const filteredHighlights =
    selectedHighlight !== ""
      ? reviewItemList?.find(
          (reviewItem) => reviewItem.id === selectedHighlight
        )?.position
      : [];

  const scrollToHighlightFromHash = () => {
    if (selectedHighlight) {
      const highlight = filteredHighlights?.find(
        (highlight) => highlight.id === selectedHighlight
      );

      if (highlight) {
        pdfHighlighter?.current?.scrollTo(highlight);
      }
    }
  };

  useEffect(() => {
    scrollToHighlightFromHash();
  }, [selectedHighlight]);

  return (
    <section className="flex flex-col flex-grow w-full min-h-full">
      {documentStatus?.status === "failed" ? (
        <section className="grid place-content-center gap-10 w-full h-full text-center">
          <img src="/errors/503.svg" alt="error" className="mx-auto h-72" />
          <h4>
            Oops! something went wrong! We will reach out to you shortly to help
            resolve the issue. Thanks for your patience.
          </h4>
        </section>
      ) : documentStatus?.status === "parsing" ? (
        <article className="flex items-center place-content-center gap-5">
          <img
            src={`/grc/frameworks-placeholder.svg`}
            alt="placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4>
              Your document has been received and is currently being processed
            </h4>
            <img
              src="/grc/data-parsing.svg"
              alt="data parsing"
              className="w-10 h-10"
            />
          </article>
        </article>
      ) : (
        <section className="flex flex-row gap-5 pb-20 w-full h-screen">
          <section
            style={{
              height: "100%",
              position: "relative",
            }}
            className="w-full h-full rounded-md"
          >
            <PdfLoader url={url} beforeLoad={<Loader />}>
              {(pdfDocument) => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  enableAreaSelection={(event) => event.altKey}
                  onScrollChange={() => {}}
                  ref={pdfHighlighter}
                  scrollRef={(scrollTo) => {
                    scrollToHighlightFromHash();
                  }}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const component = (
                      <AreaHighlight
                        isScrolledTo={highlight.id === selectedHighlight}
                        highlight={highlight}
                        onChange={(boundingRect) => {}}
                      />
                    );

                    return (
                      <Popup
                        popupContent={<span />}
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={filteredHighlights}
                />
              )}
            </PdfLoader>
          </section>

          <ReviewItems
            documentID={documentID}
            data={agreement?.data}
            selectedHighlight={selectedHighlight}
            setSelectedHighlight={setSelectedHighlight}
          />
        </section>
      )}
    </section>
  );
};

export default AgreementReview;

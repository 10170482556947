import { faXmark, faTag, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { KeyStringVal } from "src/types/general";
import {
  CreateTagForVendors,
  DeleteTagFromVendors,
  GetVendorTags,
} from "../../../../services/third-party-risk/vendors/vendors";
import ModalLayout from "../../../../layouts/ModalLayout";

const AllTags = ({
  selectedTags,
  setSelectedTags,
}: {
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
}) => {
  const [deleteTagID, setDeleteTagID] = useState<string>("");
  const [addTag, setAddTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>("");

  const { data: tags } = GetVendorTags();
  const createTag = CreateTagForVendors();
  const deleteTag = DeleteTagFromVendors();

  const deleting = deleteTag.status === "loading";

  const handleOnClose = () => setDeleteTagID("");

  return (
    <>
      <ul className="flex flex-wrap items-center gap-4 p-6 dark:bg-gray-800 rounded-2xl">
        {tags?.map((tag: KeyStringVal) => {
          return (
            <li
              key={tag.tag_id}
              className={`flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white ${
                selectedTags.includes(tag.tag_id)
                  ? "dark:bg-blue-500"
                  : "dark:hover:bg-blue-500/30 duration-100"
              } border dark:border-blue-400 rounded`}
              onClick={() => {
                if (selectedTags.includes(tag.tag_id))
                  setSelectedTags(
                    selectedTags.filter((tagID) => tag.tag_id !== tagID)
                  );
                else setSelectedTags([...selectedTags, tag.tag_id]);
              }}
            >
              <p>{tag.tag_name}</p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTags(
                    selectedTags.filter((tagID) => tagID !== tag.tag_id)
                  );
                  setDeleteTagID(tag.tag_id);
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                />
              </button>
            </li>
          );
        })}
        {!addTag ? (
          <button
            className="flex items-center gap-2 px-2 py-1 dark:bg-blue-500 dark:hover:bg-blue-500/30 duration-100 rounded"
            onClick={() => {
              setAddTag(true);
              setNewTag("");
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <h4>Add Tag</h4>
          </button>
        ) : (
          <article className="flex items-stretch w-max divide-x dark:divide-account border-1 dark:border-org rounded-sm">
            <article className="relative flex items-center gap-2 py-2 px-4 ring-0 dark:ring-search focus:ring-2 dark:focus:ring-signin dark:bg-blue-500 rounded-sm">
              <FontAwesomeIcon
                icon={faPlus}
                className="w-4 h-4 dark:text-white"
              />
              <input
                spellCheck="false"
                autoComplete="off"
                name="new tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                type="input"
                className="w-20 h-5 focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-transparent dark:bg-blue-500 dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent"
              />
            </article>
            <button
              disabled={newTag === ""}
              className="px-2 dark:disabled:text-filter dark:hover:bg-checkbox dark:disabled:bg-org/20 dark:bg-org duration-100"
              onClick={() => {
                setAddTag(false);
                setNewTag("");
                createTag.mutate({
                  tagName: newTag,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
              className="px-2 dark:text-white dark:hover:text-gray-500 duration-100"
              onClick={() => setAddTag(false)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </article>
        )}
      </ul>
      <ModalLayout showModal={deleteTagID !== ""} onClose={handleOnClose}>
        <section className="grid gap-5">
          <h4 className="text-base text-center mb-3">
            Are you sure you want to delete this tag?
          </h4>
          <section className="flex items-center gap-5 mx-auto text-sm">
            <button
              className="px-4 py-1 bg-gradient-to-b dark:from-filter dark:to-filter/60 dark:hover:from-filter dark:hover:to-filter/30 rounded-sm"
              onClick={handleOnClose}
            >
              Cancel
            </button>
            <button
              disabled={deleting}
              className="px-4 py-1 red-gradient-button rounded-sm"
              onClick={() => {
                deleteTag.mutate({
                  tagID: deleteTagID,
                });
                handleOnClose();
              }}
            >
              Delete tag
            </button>
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default AllTags;

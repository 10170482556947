const TableLayout: React.FC<{
  fullHeight?: boolean;
  height?: string;
  flexibleHeaders?: boolean;
}> = ({ fullHeight, height, flexibleHeaders, children }) => {
  return (
    <section
      className={`flex flex-col flex-grow w-full ${
        fullHeight ? "" : height ? height : "max-h-[50rem]"
      } text-sm dark:bg-card overflow-x-auto scrollbar border dark:border-gray-700 rounded-lg`}
    >
      <table
        className={`w-full h-full ${
          flexibleHeaders ? "table-auto" : "md:table-fixed"
        } rounded-lg`}
      >
        {children}
      </table>
    </section>
  );
};

export default TableLayout;

import React, { useEffect, useState } from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VendorMetadata from "./VendorMetadata";
import DocumentList from "./DocumentList/DocumentList";
import QnA from "./QnA/QnA";

const Vendor = () => {
  const parsed = parseURL();

  const [selectedTab, setSelectedTab] = useState<string>("Documents");

  const vendorID = String(parsed.vendor_id) || "";
  const assessmentID = parsed.assessment_id;

  useEffect(() => {
    sessionStorage.GRCCategory = "vendors";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-20 h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-5">
          <ReturnPage />
          <h4 className="capitalize">
            Third Party Risk <FontAwesomeIcon icon={faArrowRightLong} /> Vendors
          </h4>
        </article>
        <VendorMetadata vendorID={vendorID} assessmentID={assessmentID} />
        <nav className="flex flex-wrap items-center gap-5">
          {["Documents", "QnA"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize border-b-2 ${
                  selectedTab === tab
                    ? "dark:bg-signin/30 dark:border-signin"
                    : "dark:bg-filter/10 dark:hover:bg-filter/30 duration-100 dark:border-checkbox"
                } rounded-full`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "Documents" ? (
          <DocumentList vendorID={vendorID} assessmentID={assessmentID} />
        ) : (
          <QnA vendorID={vendorID} assessmentID={assessmentID} />
        )}
      </main>
    </PageLayout>
  );
};

export default Vendor;

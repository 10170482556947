import React, { useState } from "react";
import AgreementMetadata from "./AgreementProposalReviewMetadata";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AgreementReview from "./AgreementReview/AgreementReview";
import DocumentTabs from "./DocumentTabs";
import Sections from "./Sections";
import RedliningList from "./Redlining/RedliningList";

const AgreementProposalReview = () => {
  const parsed = parseURL();

  const [selectedTab, setSelectedTab] = useState<string>("Agreement Review");

  const documentID = String(parsed.agreement_id) || "";

  return (
    <PageLayout>
      <main className="flex flex-col flex-grow gap-5 p-4 w-full h-full text-base">
        <article className="flex items-center gap-5">
          <ReturnPage />
          <h4>Agreement & Proposal Review</h4>
        </article>
        <AgreementMetadata documentID={documentID} />
        <DocumentTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === "Agreement Review" ? (
          <AgreementReview documentID={documentID} />
        ) : selectedTab === "Sections" ? (
          <Sections documentID={documentID} />
        ) : (
          <RedliningList documentID={documentID} />
        )}
      </main>
    </PageLayout>
  );
};

export default AgreementProposalReview;

/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { GetAuditTrailTypes } from "src/services/regulation-policy/overview";
import { checkIsAdminOrSuperAdmin } from "src/utils/general";

const AuditTrailTypesFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: string[];
  setInputs: (inputs: string[]) => void;
}) => {
  const isAdmin = checkIsAdminOrSuperAdmin();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { data: auditTrailTypes } = GetAuditTrailTypes();

  const handleSelect = (value: string) => {
    if (inputs.includes(value))
      setInputs(inputs.filter((curItem: string) => curItem !== value));
    else setInputs([...inputs, value]);
  };

  return (
    <section
      className="grid content-start gap-3"
      onMouseLeave={() => setShowDropdown(false)}
    >
      <article className="flex items-center gap-3 text-left">
        {label && <h4 className="text-sm dark:text-checkbox">{label}</h4>}
        <article
          onClick={() => setShowDropdown(true)}
          className="relative px-4 py-1 w-[20rem] cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
        >
          <article className="flex items-center justify-between gap-2">
            <input
              type="input"
              autoComplete="off"
              spellCheck="false"
              placeholder="Select"
              value={`${!inputs.length ? "" : `${inputs.length} selected`}`}
              onChange={(e) => {
                if (!showDropdown) setShowDropdown(true);
                handleSelect(e.target.value);
              }}
              className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
            />
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          </article>
          {showDropdown && (
            <article className="absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm">
              {auditTrailTypes ? (
                auditTrailTypes?.length > 0 ? (
                  auditTrailTypes.map((item: string) => {
                    if (
                      ["USER_SIGNED_IN", "USER_SIGNED_OUT"].includes(item) &&
                      !isAdmin
                    )
                      return null;
                    return (
                      <button
                        key={item}
                        className="relative group flex gap-1 px-4 py-1 text-left break-words dark:hover:bg-filter/50 duration-100"
                        onClick={() => handleSelect(item)}
                      >
                        {inputs.includes(item) && (
                          <FontAwesomeIcon icon={faCheck} className="text-no" />
                        )}
                        {item.replaceAll("_", " ")}
                      </button>
                    );
                  })
                ) : (
                  <p className="px-4 py-1">No results found</p>
                )
              ) : null}
            </article>
          )}
        </article>
      </article>
      <ul className="flex flex-wrap items-center gap-2">
        {inputs.map((item: string, index: number) => {
          return (
            <li
              key={index}
              className="flex items-center gap-2 px-3 py-1 dark:bg-signin/30 border dark:border-signin rounded-sm"
            >
              {item.replaceAll("_", " ")}
              <button
                className="dark:hover:text-filter duration-100"
                onClick={() =>
                  setInputs(
                    inputs.filter((curItem: string) => curItem !== item)
                  )
                }
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default AuditTrailTypesFilter;

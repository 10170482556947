import {
  faChevronCircleDown,
  faChevronCircleRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import { userColors } from "src/constants/general";
import { DeleteRedlining } from "src/services/grc";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import { convertToUTCShortString, getCustomerID } from "src/utils/general";
import { SuggestRedliningInstructions } from "../../../../services/grc";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const RedliningDetail = ({
  documentID,
  redline,
  editor,
  selectedHighlight,
  setSelectedHighlight,
}: {
  documentID: string;
  redline: any;
  editor: any;
  selectedHighlight: any;
  setSelectedHighlight: any;
}) => {
  const customerID = getCustomerID();

  const [editID, setEditID] = useState<string>("");
  const [editedSuggestion, setEditedSuggestion] = useState<string>("");

  const deleteRedlining = DeleteRedlining(documentID);
  const { data: allUsers } = GetAllUsers(customerID, false);
  const saveSuggestions = SuggestRedliningInstructions(documentID);

  const userEmail = allUsers?.find(
    (user: KeyStringVal) => user.user_id === redline.author_id
  )?.user_email;

  return (
    <li className="group grid gap-2 dark:bg-gray-700 rounded-md">
      <section
        className={`grid gap-2 p-3 cursor-pointer ${
          selectedHighlight === redline.id
            ? "dark:bg-signin/10"
            : "dark:hover:bg-filter/60 duration-100"
        } `}
        onClick={() => {
          const { node } = editor.view.domAtPos(redline.start);
          if (node) {
            (node as any).scrollIntoView();
          }

          setSelectedHighlight(redline.id);
        }}
      >
        <header className="flex items-center justify-between gap-5 text-sm">
          {userEmail && (
            <article className="flex items-center gap-1">
              <span
                className={`grid content-center w-5 h-5 capitalize text-center text-[0.65rem] dark:text-white font-medium bg-gradient-to-b ${
                  userColors[userEmail[0]?.toLowerCase()]
                } shadow-sm dark:shadow-checkbox rounded-full`}
              >
                {userEmail[0]}
              </span>{" "}
              {userEmail}{" "}
            </article>
          )}
          <article className="flex items-center gap-2 text-xs">
            <span className="dark:text-gray-400">
              {convertToUTCShortString(redline.created_at)}
            </span>
            <button
              className="hidden group-hover:block place-self-start"
              onClick={(e) => {
                e.stopPropagation();
                deleteRedlining.mutate({
                  redliningID: redline.id,
                });
              }}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                className="text-red-300 hover:text-red-300/60 duration-100"
              />
            </button>
          </article>
        </header>
        {redline.is_failed ? (
          <p className="p-2 bg-reset/20 rounded-md">{redline.failed_status}</p>
        ) : !redline.is_ready ? (
          <p>Not readily available</p>
        ) : (
          <blockquote className="grid gap-1 break-words">
            <ReactMarkdown
              className="p-2 min-w-full dark:text-white decoration-red-500 line-through prose overflow-auto rounded-md"
              remarkPlugins={[remarkGfm]}
            >
              {redline.old_content.text}
            </ReactMarkdown>
            <p className="p-2 text-base">
              {redline.new_edits || redline.instructions}
            </p>
          </blockquote>
        )}
      </section>

      {redline.is_ready && redline.text_response?.length > 0 && (
        <Disclosure>
          {({ open }) => (
            <section className="grid content-start gap-3">
              <Disclosure.Button className="flex items-center gap-2 w-max text-sm">
                <FontAwesomeIcon
                  icon={open ? faChevronCircleDown : faChevronCircleRight}
                  className="dark:text-black"
                />
                <p>Suggestions</p>
              </Disclosure.Button>
              <Disclosure.Panel>
                <section className="grid gap-2 divide-y-1 dark:divide-white/30">
                  {redline.text_response?.map(
                    (suggestion: string, index: number) => {
                      return (
                        <article
                          key={index}
                          className="group grid gap-1 px-3 py-1 text-left text-sm"
                        >
                          {editID === `${redline.id}-${index}` ? (
                            <article className="grid gap-2">
                              <textarea
                                spellCheck="false"
                                autoComplete="off"
                                value={editedSuggestion}
                                onChange={(e) =>
                                  setEditedSuggestion(e.target.value)
                                }
                                className="py-1 w-full h-[15rem] text-sm focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-account dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                              />
                              <article className="flex items-center justify-self-end gap-3">
                                <button
                                  className="discard-button"
                                  onClick={() => {
                                    setEditID("");
                                    setEditedSuggestion("");
                                  }}
                                >
                                  Discard
                                </button>
                                <button
                                  className="save-button"
                                  onClick={() => {
                                    setEditID("");
                                    setEditedSuggestion("");
                                    saveSuggestions.mutate({
                                      anchorID: redline.id,
                                      redlining: {
                                        old_content: redline.old_content,
                                        new_edits: editedSuggestion,
                                        start: redline.start,
                                        end: redline.end,
                                      },
                                    });
                                  }}
                                >
                                  Save
                                </button>
                              </article>
                            </article>
                          ) : (
                            <article className="grid gap-2">
                              <p className="p-2 bg-no/20 rounded-md">
                                {suggestion}
                              </p>
                              <button
                                className="hidden group-hover:block p-2 dark:bg-filter dark:hover:bg-filter/60 duration-100 rounded-md"
                                onClick={() => {
                                  setEditID(`${redline.id}-${index}`);
                                  setEditedSuggestion(suggestion);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="hidden group-hover:block px-4 py-1 dark:bg-no rounded-md"
                                onClick={() =>
                                  saveSuggestions.mutate({
                                    anchorID: redline.id,
                                    redlining: {
                                      old_content: redline.old_content,
                                      new_edits: suggestion,
                                      start: redline.start,
                                      end: redline.end,
                                    },
                                  })
                                }
                              >
                                Select
                              </button>
                            </article>
                          )}
                        </article>
                      );
                    }
                  )}
                </section>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
      )}
    </li>
  );
};

export default RedliningDetail;

import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faCheck,
  faChevronCircleDown,
  faChevronCircleRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import GeneralTemporalDatepicker from "../../../components/Datepicker/GeneralTemporalDatepicker";
import { AddAssessmentCycle } from "../../../services/third-party-risk/assessments/assessment-cycles";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";
import RegularInput from "src/components/Input/RegularInput";
import { convertToMicrosec } from "../../../utils/general";
import { GetVendorGroups } from "../../../services/third-party-risk/vendors/vendor-groups";
import { Disclosure } from "@headlessui/react";

const NewAssessmentCycle = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    title: "",
    purpose: "Annual Vendor Risk Assessment",
    other: false,
    vendor_groups: {},
  });
  const [temporalStartDate, setTemporalStartDate] = useState<Date>(new Date());
  const [temporalEndDate, setTemporalEndDate] = useState<Date>(new Date());

  const { data: allVendorGroups } = GetVendorGroups();
  const { data: questionSets } = GetCustomQuestionSets();

  const addAssessmentCycle = AddAssessmentCycle();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAssessmentCycle.reset();

  return (
    <>
      <button
        className="flex items-center gap-2 place-self-end px-4 py-2 text-base font-light dark:text-white dark:bg-blue-500 dark:hover:bg-blue-500/70 duration-100 rounded-xl"
        onClick={() => {
          setShow(true);
          setInputs({
            title: "",
            purpose: "Annual Vendor Risk Assessment",
            other: false,
            vendor_groups: {},
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 mb-10">
          <h3 className="flex items-center gap-2 text-lg">
            <FontAwesomeIcon icon={faPlus} />
            New Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <GeneralTemporalDatepicker
            temporalStartDate={temporalStartDate}
            setTemporalStartDate={setTemporalStartDate}
            temporalEndDate={temporalEndDate}
            setTemporalEndDate={setTemporalEndDate}
            ignoreWarning
          />
          <section className="grid gap-1">
            <h4>Purpose</h4>
            <article className="flex items-center gap-10">
              <article className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={inputs.purpose === "Annual Vendor Risk Assessment"}
                  className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-checkbox focus:ring-opacity-50"
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      purpose: "Annual Vendor Risk Assessment",
                      other: false,
                    });
                  }}
                />
                <label>Annual Vendor Risk Assessment</label>
              </article>
              <article className="flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={inputs.other}
                  className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-checkbox focus:ring-opacity-50"
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      other: true,
                      purpose: "",
                    })
                  }
                />
                <article className="grid gap-1">
                  <label>Other</label>
                  {inputs.other && (
                    <textarea
                      value={inputs.purpose}
                      onChange={(e) =>
                        setInputs({ ...inputs, purpose: e.target.value })
                      }
                      className="py-1 w-96 h-40 text-sm focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-black dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                    />
                  )}
                </article>
              </article>
            </article>
          </section>
          <section className="grid gap-2">
            <h4>Vendor Groups</h4>
            {allVendorGroups?.data.map((vendorGroup) => {
              const vendorSelected =
                inputs.vendor_groups[vendorGroup.generated_id];
              return (
                <article key={vendorGroup.generated_id}>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex items-center gap-2 px-3 py-1 cursor-pointer bg-gradient-to-r dark:from-checkbox/70 dark:to-white/10 dark:hover:to-white/30 duration-100 rounded-md">
                          {vendorSelected && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-yellow-500"
                            />
                          )}
                          <h4 onClick={() => {}}>{vendorGroup.name}</h4>
                          <FontAwesomeIcon
                            icon={
                              open ? faChevronCircleDown : faChevronCircleRight
                            }
                            className="dark:text-black"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="grid gap-5 p-4 dark:bg-panel">
                          <ul className="grid gap-2">
                            <h4>Question Sets</h4>
                            {questionSets?.data.map((questionSet) => {
                              const selected =
                                inputs.vendor_groups[
                                  vendorGroup.generated_id
                                ] === questionSet.generated_id;
                              return (
                                <li
                                  key={questionSet.generated_id}
                                  className={`flex items-center gap-2 p-2 cursor-pointer border ${
                                    selected
                                      ? "dark:border-yellow-500 dark:hover:border-yellow-500/60"
                                      : "dark:border-white dark:hover:border-white/60"
                                  } duration-100 rounded-md`}
                                  onClick={() => {
                                    if (selected) {
                                      let newVendorGroups =
                                        inputs.vendor_groups;
                                      delete newVendorGroups[
                                        vendorGroup.generated_id
                                      ];
                                      setInputs({
                                        ...inputs,
                                        vendor_groups: newVendorGroups,
                                      });
                                    } else
                                      setInputs({
                                        ...inputs,
                                        vendor_groups: {
                                          ...inputs.vendor_groups,
                                          [vendorGroup.generated_id]:
                                            questionSet.generated_id,
                                        },
                                      });
                                  }}
                                >
                                  {selected && (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-no"
                                    />
                                  )}
                                  {questionSet.name}
                                </li>
                              );
                            })}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </article>
              );
            })}
          </section>
          <button
            disabled={
              inputs.title === "" ||
              Object.keys(inputs.vendor_groups).length === 0 ||
              temporalStartDate === temporalEndDate
            }
            className="flex items-center justify-self-center gap-2 px-4 py-2 w-max dark:text-white green-gradient-button rounded-sm"
            onClick={() => {
              addAssessmentCycle.mutate({
                formData: {
                  title: inputs.title,
                  purpose: inputs.purpose,
                  start_date: convertToMicrosec(temporalStartDate),
                  end_date: convertToMicrosec(temporalEndDate),
                  vendor_groups: inputs.vendor_groups,
                },
              });
              handleOnClose();
            }}
          >
            <h4>Done</h4>
          </button>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={addAssessmentCycle.data !== undefined}
        onClose={handleOnCloseConfirmation}
      >
        <section className="grid content-start gap-5 py-4 h-full mx-auto">
          <article className="flex items-center gap-2">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-6 h-6"
            />
            <h3 className="text-lg">New Assessment has been created!</h3>
          </article>
          <button
            className="px-4 py-2 mx-auto w-max dark:hover:bg-filter/30 duration-100 rounded-full"
            onClick={() => {
              navigate(
                `/third-party-risk/assessment-cycles/details?assessment_id=${addAssessmentCycle.data.assessment_id}`
              );
              handleOnCloseConfirmation();
            }}
          >
            <h4>
              Go to Assessment <FontAwesomeIcon icon={faArrowRightLong} />
            </h4>
          </button>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewAssessmentCycle;

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { userColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import { User } from "src/types/settings";
import { getCustomerID } from "src/utils/general";

const UserInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  allowUnselect,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  allowUnselect?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: allUsers } = GetAllUsers(customerID, false);

  const filteredUsers = allUsers?.filter((user: KeyStringVal) =>
    user.user_email
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );
  const userEmail =
    inputs[keyName] !== ""
      ? allUsers?.find((user: KeyStringVal) => user.user_id === inputs[keyName])
          ?.user_email
      : "";

  return (
    <article className="flex items-center gap-2">
      <h4 className="justify-self-start">{label}</h4>
      <article
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
        className="relative px-4 py-1 text-left cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select User"
            value={query ? query : userEmail}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-7 left-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm">
            {filteredUsers?.map((user: User) => {
              const selected = inputs[keyName] === user.user_id;
              return (
                <button
                  key={user.user_email}
                  className={`relative flex items-start gap-2 px-4 py-1 break-words dark:hover:bg-filter/50 ${
                    userEmail === user.user_email ? "dark:bg-filter" : ""
                  } duration-200`}
                  onClick={() => {
                    if (allowUnselect && selected) {
                      setQuery("");
                      setInputs({ ...inputs, [keyName]: "" });
                    } else {
                      setQuery(user.user_email);
                      setInputs({ ...inputs, [keyName]: user.user_id });
                    }
                  }}
                >
                  {selected && (
                    <FontAwesomeIcon icon={faCheck} className="text-no" />
                  )}
                  <span
                    className={`grid content-center w-5 h-5 capitalize text-center text-[0.65rem] dark:text-white font-medium bg-gradient-to-b ${
                      userColors[user.user_email[0].toLowerCase()]
                    } shadow-sm dark:shadow-checkbox rounded-full`}
                  >
                    {user.user_email[0]}
                  </span>
                  <p className="grid text-left">
                    {user.user_email}{" "}
                    <span className="text-xs dark:text-checkbox">
                      {user.user_name}
                    </span>
                  </p>
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default UserInput;

import React from "react";
import ReactMarkdown from "react-markdown";
import { UpdatePolicySections } from "src/services/regulation-policy/policy";
import remarkGfm from "remark-gfm";
import {
  MDXEditor,
  toolbarPlugin,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  tablePlugin,
  BoldItalicUnderlineToggles,
  UndoRedo,
  BlockTypeSelect,
  CodeToggle,
  CreateLink,
  DiffSourceToggleWrapper,
  InsertCodeBlock,
  InsertFrontmatter,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  imagePlugin,
  frontmatterPlugin,
  diffSourcePlugin,
  linkDialogPlugin,
  StrikeThroughSupSubToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

const GeneratedContent = ({
  documentType,
  documentID,
  subsection,
  filter,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  subsection: any;
  filter?: string;
  editSections?: any;
  setEditSections?: any;
}) => {
  const isPolicy = documentType === "policies";
  const isGeneratedPolicy =
    isPolicy && sessionStorage.selectedPolicyGroupName === "Generated-Policies";

  const updatePolicySections = UpdatePolicySections();

  return (
    <section className="flex flex-col flex-grow gap-2 w-full h-screen text-black break-words">
      {editSections &&
      Object.keys(editSections).includes(subsection.generated_id) ? (
        <>
          <article className="flex items-center place-content-end gap-5">
            <button
              className="discard-button"
              onClick={() =>
                setEditSections(
                  Object.fromEntries(
                    Object.entries(editSections).filter(
                      ([key]) => key !== subsection.generated_id
                    )
                  )
                )
              }
            >
              Discard
            </button>
            {subsection.content !==
              editSections[subsection.generated_id].content && (
              <button
                className="flex items-center gap-1 px-3 py-1 text-white dark:bg-no dark:hover:bg-no/60 duration-100 rounded-md"
                onClick={() => {
                  updatePolicySections.mutate({
                    policyID: documentID,
                    editSections: editSections,
                  });
                  setEditSections({});
                }}
              >
                Save
              </button>
            )}
          </article>

          <MDXEditor
            className="min-w-full min-h-full prose overflow-auto"
            markdown={subsection.content}
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <StrikeThroughSupSubToggles />
                    <BlockTypeSelect />
                    <CodeToggle />
                    <CreateLink />
                    <InsertCodeBlock />
                    <InsertFrontmatter />
                    <InsertImage />
                    <InsertTable />
                    <InsertThematicBreak />
                    <ListsToggle />
                    <DiffSourceToggleWrapper />
                  </>
                ),
              }),
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              markdownShortcutPlugin(),
              tablePlugin(),
              imagePlugin(),
              frontmatterPlugin(),
              diffSourcePlugin({
                diffMarkdown: subsection.content,
                viewMode: "rich-text",
                readOnlyDiff: true,
              }),
              linkDialogPlugin(),
              codeBlockPlugin(),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  py: "Python",
                  "": "Text",
                },
              }),
            ]}
            onChange={(content) =>
              setEditSections({
                ...editSections,
                [subsection.generated_id]: {
                  content: content,
                },
              })
            }
          />
        </>
      ) : (
        <>
          {isPolicy &&
            editSections &&
            !editSections[subsection.generated_id]?.content &&
            filter !== "Suggest New Mapping" && (
              <button
                className="sticky top-0 place-self-end px-2 py-1 w-max text-white dark:bg-black dark:hover:bg-black/60 duration-100 rounded-md"
                onClick={() =>
                  setEditSections({
                    ...editSections,
                    [subsection.generated_id]: {
                      content: subsection.content,
                    },
                  })
                }
              >
                Edit
              </button>
            )}
          {isGeneratedPolicy ? (
            <ReactMarkdown
              className="min-w-full prose overflow-auto"
              remarkPlugins={[remarkGfm]}
            >
              {subsection.content}
            </ReactMarkdown>
          ) : (
            <p className="grid content-start gap-2 w-full">
              {subsection.content
                .split("\n")
                .map((phrase: string, index: number) => (
                  <span
                    key={index}
                    className="flex flex-wrap items-center gap-1 w-full"
                  >
                    {phrase.split(" ").map((word, wordIndex) => (
                      <span
                        key={wordIndex}
                        className={`${
                          subsection.search_highlight?.matched_tokens?.includes(
                            word
                          )
                            ? "text-black bg-yellow-300"
                            : ""
                        }`}
                      >
                        {word}
                      </span>
                    ))}
                  </span>
                ))}
            </p>
          )}
        </>
      )}
    </section>
  );
};

export default GeneratedContent;

import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

const RegionFilter = ({
  label,
  regions,
  selected,
  setSelected,
}: {
  label: string;
  regions: string[];
  selected: string;
  setSelected: (selected: string) => void;
}) => {
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const filteredRegions = regions.filter((region) =>
    region
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <section className="flex items-center gap-3 z-40">
      <h4 className="dark:text-checkbox">{label} *:</h4>
      <section
        className="flex items-start gap-3 text-xs text-left"
        onMouseLeave={() => setShowDropdown(false)}
      >
        <article
          onClick={() => setShowDropdown(true)}
          className="relative px-4 py-1 w-full cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
        >
          <article className="flex items-center justify-between gap-2">
            <input
              type="input"
              autoComplete="off"
              spellCheck="false"
              placeholder="Select"
              value={selected}
              onChange={(e) => {
                if (!showDropdown) setShowDropdown(true);
                setSelected(e.target.value);
                setQuery(e.target.value);
              }}
              className="w-max border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
            />
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
            </span>
          </article>
          {showDropdown && (
            <article
              className={`absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm`}
            >
              {filteredRegions?.map((item: string, index: number) => (
                <button
                  key={index}
                  className={`relative group px-4 py-1 text-left break-words dark:hover:bg-filter/50 ${
                    selected === item ? "dark:bg-filter" : ""
                  } duration-100`}
                  onClick={() => setSelected(item)}
                >
                  <p>{item}</p>
                </button>
              ))}
            </article>
          )}
        </article>
      </section>
    </section>
  );
};

export default RegionFilter;

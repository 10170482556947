import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { initialMatch } from "src/constants/graph";
import { GetCypherAutocompleteValues } from "src/services/graph/autocomplete";
import { useGeneralStore } from "src/stores/general";
import { KeyStringVal } from "src/types/general";

const CypherMatchFilter = ({
  list,
  queryParam,
  keyName,
  match,
  setMatch,
  conditions,
  startTime,
  endTime,
}: {
  list?: string[];
  queryParam: string;
  keyName: string;
  match: KeyStringVal;
  setMatch: (match: KeyStringVal) => void;
  conditions: KeyStringVal[];
  startTime: number;
  endTime: number;
}) => {
  const { env } = useGeneralStore();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [hover, setHover] = useState<string>("");

  const value = match[keyName];

  const { data: autocompleteValues } = GetCypherAutocompleteValues(
    env,
    queryParam,
    match,
    conditions,
    startTime,
    endTime,
    ""
  );

  const filteredList = (autocompleteValues?.query_params || list)?.filter(
    (item: string) =>
      item
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
  );

  const nodeTypeMetadata = autocompleteValues?.metadata;

  const handleSelect = (item: string) => {
    switch (keyName) {
      case "match1Name":
        setMatch({
          ...initialMatch,
          [keyName]: item,
        });
        break;
      case "match2Name":
        if (match.match1Name === "id")
          setMatch({
            ...match,
            [keyName]: item,
            relName: "",
            relValue: "",
            match2Value: "",
          });
        else
          setMatch({
            ...match,
            [keyName]: item,
            match2Value: "",
          });
        break;
      default:
        if (
          match.match1Name === "id" &&
          ["radius", "impact_radius"].includes(keyName)
        )
          setMatch({
            ...match,
            [keyName]: item,
            relValue: "",
            match2Name: "",
            match2Value: "",
          });
        else
          setMatch({
            ...match,
            [keyName]: item,
          });
    }
  };

  return (
    <section
      className="flex items-start gap-3 text-xs text-left"
      onMouseLeave={() => setShowDropdown(false)}
    >
      <article
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100 focus:outline-none rounded-t-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={value}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              handleSelect(e.target.value);
            }}
            className="w-max border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
          </span>
        </article>

        {showDropdown && (
          <article
            className={`absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b-sm`}
          >
            {filteredList?.map((item: string) => (
              <button
                key={item}
                className={`relative group px-4 py-1 text-left break-words dark:hover:bg-filter/50 ${
                  value === item ? "dark:bg-filter" : ""
                } duration-100`}
                onClick={() => handleSelect(item)}
                onMouseEnter={() => setHover(item)}
                onMouseLeave={() => setHover("")}
              >
                <p>{item}</p>
              </button>
            ))}
          </article>
        )}
      </article>
      {nodeTypeMetadata && nodeTypeMetadata[hover] && (
        <ul className="grid gap-1 px-4 py-1 dark:bg-tooltip border-1 dark:border-checkbox">
          {nodeTypeMetadata[hover].name?.map((name: string, index: number) => {
            return (
              <li key={index} className="flex items-start gap-2 w-max">
                <img
                  src={`/general/integrations/${nodeTypeMetadata[hover].cloud[
                    index
                  ].toLowerCase()}.svg`}
                  alt={nodeTypeMetadata[hover].cloud[index]}
                  className="w-4 h-4"
                />
                {name}
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default CypherMatchFilter;

/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentFilter = ({
  label,
  selectedDocument,
  setSelectedDocument,
  list,
}: {
  label?: string;
  selectedDocument: KeyStringVal;
  setSelectedDocument: (selectedDocument: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  return (
    <DropdownLayout
      label={label}
      value={selectedDocument.document_name || selectedDocument.name}
      width="w-[30rem]"
      placeholder="Select document"
    >
      {list?.map((document: KeyStringVal, index: number) => (
        <button
          key={index}
          className={`relative px-4 py-1 w-full break-all text-left dark:hover:bg-filter/50 ${
            selectedDocument.document_name === document.document_name ||
            selectedDocument.name === document.name
          })
              ? "dark:bg-filter"
              : ""
          } duration-100`}
          onClick={() => setSelectedDocument(document)}
        >
          <p>{document.document_name || document.name}</p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default DocumentFilter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import Sheet from "../../../../../QuestionnaireRFP/QuestionBanks/QuestionBankList/UploadQuestionBank/Sheet";

const UploadVendorResponses = ({
  file,
  fileIndex,
  inputs,
  setInputs,
}: {
  file: any;
  fileIndex: number;
  inputs;
  setInputs;
}) => {
  const [headers, setHeaders] = useState<any>({});
  const [numOfRows, setNumOfRows] = useState<any>({});
  const [markedAsQuestionCol, setMarkedAsQuestionCol] = useState<any>({});
  const [markedAsAnswerCol, setMarkedAsAnswerCol] = useState<any>({});

  useEffect(() => {
    if (
      Object.keys(markedAsQuestionCol).length > 0 &&
      Object.keys(markedAsAnswerCol).length > 0
    ) {
      let toExtract = {} as any;
      Object.keys(headers).forEach((sheet, sheetIndex: number) => {
        if (
          markedAsQuestionCol.hasOwnProperty(sheet) &&
          markedAsQuestionCol.hasOwnProperty(sheet) &&
          numOfRows.hasOwnProperty(sheet)
        )
          toExtract[sheetIndex] = {
            questions: markedAsQuestionCol[sheet],
            answers: markedAsAnswerCol[sheet],
            num_header_rows: numOfRows[sheet],
          };
      });
      setInputs({
        ...inputs,
        to_extracts: inputs.to_extracts.map((curToExtract, index) => {
          if (fileIndex === index) return JSON.stringify(toExtract);
          return curToExtract;
        }),
      });
    }
  }, [markedAsQuestionCol, markedAsAnswerCol]);

  useEffect(() => {
    if (file) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = (event) => {
        let data = event.target?.result;
        let workbook = read(data, { type: "binary" });
        const tempHeaders = {} as any;
        workbook.SheetNames.forEach((sheet: string) => {
          const rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,
            defval: "",
          });
          let curHeaders = [];
          if (numOfRows[sheet] > 1) {
            const maxHeaderRows = Math.max(
              ...(workbook.Sheets[sheet]["!merges"] || [])?.reduce(
                (pV: number[], cV: any) => [...pV, cV.e.r],
                []
              )
            );
            const topCols = rowObject[0] as string[];
            let topColIdx = 0;
            curHeaders = (
              rowObject[Math.max(maxHeaderRows, 2)] as string[]
            )?.map((col, idx) => {
              if (col !== "") return `${topCols[topColIdx]} - ${col}`;
              else {
                topColIdx = idx + 1;
                return topCols[idx];
              }
            });
          } else curHeaders = rowObject[0] as string[];
          tempHeaders[sheet] = curHeaders;
        });
        setHeaders(tempHeaders);
      };
    } else setHeaders({});
  }, [file, numOfRows]);

  return (
    <section className="grid content-start gap-5 h-full">
      {Object.keys(headers).length > 0 && (
        <section className="grid gap-5 overflow-auto scrollbar">
          {Object.keys(headers).map((sheet, index) => {
            return (
              <Sheet
                key={index}
                headers={headers}
                sheet={sheet}
                numOfRows={numOfRows}
                setNumOfRows={setNumOfRows}
                markedAsQuestionCol={markedAsQuestionCol}
                setMarkedAsQuestionCol={setMarkedAsQuestionCol}
                markedAsAnswerCol={markedAsAnswerCol}
                setMarkedAsAnswerCol={setMarkedAsAnswerCol}
              />
            );
          })}
        </section>
      )}
    </section>
  );
};

export default UploadVendorResponses;

import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { UploadDocuments } from "../../../../../../services/third-party-risk/vendors/vendors";
import MultipleVendorFileInput from "../../../../../../components/Input/MultipleVendorFileInput";
import { ThreeDots } from "react-loader-spinner";
import { queryClient } from "src/App";

const NewDocuments = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    files: [],
    document_types: [],
    frameworks: [],
    to_extracts: [],
  });

  const uploadDocs = UploadDocuments(vendorID, assessmentID);

  const handleOnClose = () => {
    setShow(false);
    uploadDocs.reset();
  };

  return (
    <>
      <button
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            files: [],
            document_types: [],
            frameworks: [],
            to_extracts: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Documents</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        {uploadDocs.status === "success" ? (
          <section className="grid content-start gap-5 py-4 h-full mx-auto text-center">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-12 h-12 mx-auto"
            />
            <h3 className="text-lg">
              Documents have been uploaded. GRC AI Agents are on it! Will notify
              you as soon as the document is read, parsed, understood, and
              analyzed by the autonomous agent.
            </h3>
          </section>
        ) : (
          <section className="grid content-start gap-5 h-full overflow-auto scrollbar">
            <h3 className="flex items-center gap-2 text-lg">
              <FontAwesomeIcon icon={faUpload} />
              Upload Documents
            </h3>
            <>
              {uploadDocs.status === "loading" ? (
                <article className="flex flex-wrap items-center place-content-center gap-2">
                  Files are being uploaded{" "}
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="50"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />{" "}
                </article>
              ) : (
                <>
                  <MultipleVendorFileInput
                    label="Documents (.pdf, .docx, .xlsx)"
                    types={["pdf", "docx", "xlsx"]}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <button
                    disabled={
                      inputs.files.length === 0 ||
                      inputs.document_types.includes("") ||
                      inputs.document_types.some(
                        (documentType, index) =>
                          (["Audit Reports", "Privacy Documents"].includes(
                            documentType
                          ) &&
                            inputs.frameworks[index] === "") ||
                          (documentType === "Questionnaire Responses" &&
                            inputs.to_extracts[index] === "")
                      )
                    }
                    className="flex items-center justify-self-center gap-2 px-4 py-2 w-max dark:text-white green-gradient-button rounded-sm"
                    onClick={() => {
                      const formData = new FormData();

                      if (inputs.files.length > 0)
                        inputs.files.forEach((file: any) =>
                          formData.append("files", file)
                        );
                      if (inputs.document_types.length > 0)
                        inputs.document_types.forEach((documentType: any) =>
                          formData.append("document_types", documentType)
                        );
                      if (inputs.frameworks.length > 0)
                        inputs.frameworks.forEach((framework: any) =>
                          formData.append("frameworks", framework)
                        );
                      if (inputs.to_extracts.length > 0)
                        inputs.to_extracts.forEach((toExtract: any) =>
                          formData.append("to_extracts", toExtract)
                        );

                      uploadDocs.mutate(
                        {
                          formData: formData,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries([
                              "get-vendor-documents",
                            ]);
                            handleOnClose();
                          },
                        }
                      );
                    }}
                  >
                    Process
                  </button>
                </>
              )}
            </>
          </section>
        )}
      </ModalLayout>
    </>
  );
};

export default NewDocuments;

import React from "react";
import { parseURL } from "../../../../../utils/general";
import CoverageReport from "./CoverageReport";

const ReportDetail = () => {
  const parsed = parseURL();
  return <>{parsed.report_type === "coverage" ? <CoverageReport /> : null}</>;
};

export default ReportDetail;

/* eslint-disable react-hooks/exhaustive-deps */
// src/Tiptap.tsx
import { BubbleMenu, EditorContent, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import { Markdown } from "tiptap-markdown";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOMSerializer } from "prosemirror-model";
import { CreateRedlining } from "../../../../services/grc";
import Link from "@tiptap/extension-link";
import { ThreeDots } from "react-loader-spinner";
import HighlightPopup from "./HighlightPopup";

export const getSelectedText = (
  editor: Editor,
  returnType = "text"
): string | null => {
  if (!editor) return null;
  const { state } = editor;
  const { from, to, empty } = state.selection;

  if (empty) return null;

  if (returnType === "node") {
    const nodesArray: string[] = [];

    state.doc.nodesBetween(from, to, (node, pos, parent) => {
      if (parent === state.doc) {
        const serializer = DOMSerializer.fromSchema(editor.schema);
        const dom = serializer.serializeNode(node);
        const tempDiv = document.createElement("div");
        tempDiv.appendChild(dom);
        nodesArray.push(tempDiv.innerHTML);
      }
    });

    return nodesArray.join("");
  }

  return editor.state.doc.textBetween(from, to, " ");
};

const getHTMLFromSelection = (editor: Editor) => {
  const { state } = editor;

  const slice = state.selection.content();
  const serializer = DOMSerializer.fromSchema(editor.schema);
  const fragment = serializer.serializeFragment(slice.content);
  const div = document.createElement("div");
  div.appendChild(fragment);

  return div.innerHTML;
};

const Tiptap = ({
  documentID,
  editor,
}: {
  documentID: string;
  editor: any;
}) => {
  const markdownOutput = editor.storage.markdown.getMarkdown();

  const createRedlining = CreateRedlining(documentID);

  return (
    <section className="p-4 min-w-full h-full prose bg-white overflow-auto rounded-md">
      <BubbleMenu
        editor={editor}
        pluginKey="bubbleMenuLink"
        tippyOptions={{ duration: 100 }}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show the bubble menu for non-links.
          return from !== to && !editor.isActive("link");
        }}
      >
        <>
          {createRedlining.status === "loading" ? (
            <p className="flex items-start gap-2 px-4 py-2 dark:text-white dark:bg-panel rounded-md z-10">
              Processing your instructions
              <ThreeDots
                visible={true}
                height="20"
                width="50"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </p>
          ) : (
            <section className="grid text-sm text-white">
              <h4 className="p-2 font-light text-white dark:bg-gray-600 rounded-t-md">
                <FontAwesomeIcon icon={faPlus} /> Instruction for Redlining
              </h4>
              <section className="grid gap-1 -mt-2 p-2 font-light dark:bg-gray-700 rounded-b-md">
                <span>How would you like to redline this?</span>
                <textarea
                  id="suggestion"
                  name="suggestion"
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.value !== "") {
                      const htmlText = getHTMLFromSelection(editor);
                      const { state } = editor;
                      const { from, to } = state.selection;
                      createRedlining.mutate({
                        redlining: {
                          old_content: htmlText,
                          new_edits: e.target.value,
                          start: from,
                          end: to,
                        },
                      });
                      editor
                        .chain()
                        .focus()
                        .setColor("red")
                        .toggleStrike()
                        .focus()
                        .run();
                    }
                  }}
                  className="p-2 w-[15rem] h-[5rem] text-sm dark:text-black focus:outline-none focus:border-transparent focus:ring-0 resize-none overflow-auto scrollbar rounded-md"
                />
                <span className="place-self-end font-light">
                  Press Enter to Save
                </span>
              </section>
            </section>
          )}
        </>
      </BubbleMenu>

      <EditorContent editor={editor} content={markdownOutput} />
    </section>
  );
};

export default Tiptap;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import Loader from "src/components/Loader/Loader";
import { GetVendorResponses } from "src/services/third-party-risk/vendors/vendors";
import Response from "./Response";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";

const VendorResponseList = ({
  vendorID,
  assessmentID,
  documentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
  documentID: string;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const {
    data: vendorResponses,
    status: vendorResponseStatus,
    refetch,
    isFetching,
  } = GetVendorResponses(vendorID, assessmentID, documentID, pageNumber);

  const totalCount = vendorResponses?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <Disclosure>
      {({ open }) => (
        <section className="grid content-start gap-3">
          <Disclosure.Button className="flex items-center gap-2 w-max text-sm">
            <FontAwesomeIcon
              icon={open ? faChevronCircleDown : faChevronCircleRight}
              className="dark:text-black"
            />
            <p>{open ? "Hide" : "Show"} Responses</p>
          </Disclosure.Button>
          <Disclosure.Panel>
            <section className="flex flex-col flex-grow gap-5">
              {vendorResponseStatus === "loading" ? (
                <Loader />
              ) : !isFetching ? (
                vendorResponses?.data.length > 0 ? (
                  <section className="flex flex-col flex-grow gap-5">
                    <header className="flex items-center justify-between gap-10">
                      <h4>Responses</h4>
                      <TablePagination
                        totalPages={totalPages}
                        beginning={beginning}
                        end={end}
                        totalCount={totalCount}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                      />
                    </header>
                    <ul className="flex flex-col flex-grow gap-5 pb-20">
                      {vendorResponses?.data.map(
                        (vendorResponse: any, index: number) => {
                          return (
                            <Response
                              key={index}
                              vendorID={vendorID}
                              assessmentID={assessmentID}
                              questionIndex={index}
                              question={vendorResponse}
                            />
                          );
                        }
                      )}
                    </ul>
                  </section>
                ) : (
                  <section className="flex items-center gap-10 w-full h-full">
                    <img
                      src="/grc/frameworks-placeholder.svg"
                      alt="frameworks placeholder"
                      className="w-40 h-40"
                    />
                    <article className="grid gap-3">
                      <header className="flex items-center gap-3">
                        <h4 className="text-xl font-extrabold">
                          Vendor Responses
                        </h4>
                        <button
                          className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
                          onClick={() => refetch()}
                        >
                          <FontAwesomeIcon
                            icon={faRefresh}
                            className="text-no"
                          />
                          Refresh
                        </button>
                      </header>
                      <h4>No vendor responses available</h4>
                    </article>
                  </section>
                )
              ) : null}
            </section>
          </Disclosure.Panel>
        </section>
      )}
    </Disclosure>
  );
};

export default VendorResponseList;

import {
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import Markdown from "react-markdown";
import { UpdatePolicySections } from "src/services/regulation-policy/policy";

const SubsectionContent = ({
  documentType,
  documentID,
  subsection,
  duplicateSections,
  filter,
  search,
  searchedRowIndex,
  sectionIndex,
  subSectionIndex,
  editSections,
  setEditSections,
  opened,
  setOpened,
}: {
  documentType: string;
  documentID: string;
  subsection: any;
  duplicateSections?: any;
  filter?: string;
  search?: boolean;
  searchedRowIndex?: number;
  sectionIndex?: number;
  subSectionIndex?: number;
  editSections?: any;
  setEditSections?: any;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
}) => {
  const isPolicy = documentType === "policies";
  const otherDocumentTypes = !["policies", "frameworks", "circulars"].includes(
    documentType
  );

  const updatePolicySections = UpdatePolicySections();

  return (
    <>
      {subsection.content && (
        <Disclosure
          defaultOpen={
            search === true ||
            searchedRowIndex ===
              (Number(sectionIndex) + 1) * Number(subSectionIndex)
          }
        >
          {({ open }) => {
            const show =
              opened ||
              (otherDocumentTypes && open) ||
              duplicateSections?.data !== undefined;

            return (
              <section className="text-sm">
                <Disclosure.Button
                  className="flex items-center gap-2"
                  onClick={() => {
                    if (opened != null && setOpened) setOpened(!opened);
                  }}
                >
                  <FontAwesomeIcon
                    icon={show ? faChevronCircleDown : faChevronCircleRight}
                    className="dark:text-black"
                  />
                  <h4>{show ? "Hide" : "Show"} content</h4>
                </Disclosure.Button>
                <Transition
                  show={show}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="grid content-start gap-2 p-3 w-full max-h-[36rem] break-words dark:bg-black/60 rounded-md overflow-auto scrollbar">
                    {editSections &&
                    Object.keys(editSections).includes(
                      subsection.generated_id
                    ) ? (
                      <>
                        <article className="flex items-center place-content-end gap-5">
                          <button
                            className="discard-button"
                            onClick={() =>
                              setEditSections(
                                Object.fromEntries(
                                  Object.entries(editSections).filter(
                                    ([key]) => key !== subsection.generated_id
                                  )
                                )
                              )
                            }
                          >
                            Discard
                          </button>
                          {subsection.content !==
                            editSections[subsection.generated_id].content && (
                            <button
                              className="flex items-center gap-1 px-3 py-1 dark:bg-no dark:hover:bg-no/60 duration-100 rounded-md"
                              onClick={() => {
                                updatePolicySections.mutate({
                                  policyID: documentID,
                                  editSections: editSections,
                                });
                                setEditSections({});
                              }}
                            >
                              Save
                            </button>
                          )}
                        </article>

                        <textarea
                          spellCheck="false"
                          autoComplete="off"
                          value={editSections[subsection.generated_id]?.content}
                          onChange={(e) =>
                            setEditSections({
                              ...editSections,
                              [subsection.generated_id]: {
                                content: e.target.value,
                              },
                            })
                          }
                          className="py-1 w-full h-[20rem] text-sm focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-transparent dark:bg-account dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                        />
                      </>
                    ) : (
                      <>
                        {isPolicy &&
                          editSections &&
                          !editSections[subsection.generated_id]?.content &&
                          filter !== "Suggest New Mapping" && (
                            <button
                              className="sticky top-0 justify-self-end px-2 py-1 dark:bg-filter dark:hover:bg-filter/60 duration-100 rounded-md"
                              onClick={() =>
                                setEditSections({
                                  ...editSections,
                                  [subsection.generated_id]: {
                                    content: subsection.content,
                                  },
                                })
                              }
                            >
                              Edit
                            </button>
                          )}
                        {subsection.content.includes("**") ? (
                          <Markdown>{subsection.content}</Markdown>
                        ) : (
                          <p className="grid content-start gap-2 w-full">
                            {subsection.content
                              .split("\n")
                              .map((phrase: string, index: number) => (
                                <span
                                  key={index}
                                  className="flex flex-wrap items-center gap-1 w-full"
                                >
                                  {phrase.split(" ").map((word, wordIndex) => (
                                    <span
                                      key={wordIndex}
                                      className={`${
                                        subsection.search_highlight?.matched_tokens?.includes(
                                          word
                                        )
                                          ? "text-black bg-yellow-300"
                                          : ""
                                      }`}
                                    >
                                      {word}
                                    </span>
                                  ))}
                                </span>
                              ))}
                          </p>
                        )}
                      </>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </section>
            );
          }}
        </Disclosure>
      )}
    </>
  );
};

export default SubsectionContent;

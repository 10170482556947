import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { convertBytes } from "../../utils/general";
import DocumentTypeFilter from "../Filter/ThirdPartyRisk/DocumentTypeFilter";
import SelectDocFrameworkFilter from "../Filter/ThirdPartyRisk/SelectDocFrameworkFilter";
import UploadVendorResponses from "../../pages/ThirdPartyRisk/Vendors/VendorList/Vendor/DocumentList/UploadVendorResponses";

const MultipleVendorFileInput = ({
  label,
  types,
  inputs,
  setInputs,
}: {
  label: string;
  types: string[];
  inputs: any;
  setInputs: any;
}) => {
  return (
    <article className="grid content-start gap-1">
      <h4>Upload {label}</h4>
      <FileUploader
        multiple
        handleChange={(files: any) => {
          const fileList = [...inputs.files, ...[...files]];
          const emptyList = Array([...files].length).fill("");
          setInputs({
            ...inputs,
            files: fileList,
            document_types: [...inputs.document_types, ...emptyList],
            frameworks: [...inputs.frameworks, ...emptyList],
            to_extracts: [...inputs.to_extracts, ...emptyList],
          });
        }}
        name="file"
        types={types}
        children={
          <button className="group flex flex-col gap-2 items-center px-10 py-12 dark:hover:bg-icon duration-100 border-dashed border-2 dark:border-checkbox w-full h-full mx-auto tracking-wide rounded-sm">
            <svg
              width="21"
              height="22"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto"
            >
              <path
                d="M5.00447 14.9527C4.01948 14.9538 3.06872 14.5914 2.33434 13.935C1.59995 13.2786 1.13358 12.3743 1.02458 11.3953C0.915574 10.4164 1.1716 9.43166 1.7436 8.62978C2.31561 7.8279 3.16337 7.26526 4.12447 7.04966C3.84639 5.75276 4.09489 4.3985 4.81532 3.28481C5.53574 2.17113 6.66906 1.38924 7.96597 1.11116C9.26288 0.833085 10.6171 1.08159 11.7308 1.80201C12.8445 2.52243 13.6264 3.65576 13.9045 4.95266H14.0045C15.2444 4.95142 16.4406 5.41095 17.3608 6.24204C18.281 7.07313 18.8596 8.21649 18.9842 9.45017C19.1088 10.6838 18.7706 11.9198 18.0352 12.9181C17.2997 13.9165 16.2196 14.6059 15.0045 14.8527M13.0045 11.9527L10.0045 8.95266M10.0045 8.95266L7.00447 11.9527M10.0045 8.95266V20.9527"
                stroke="#7993B0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>
              <span className="font-bold"> Click to upload </span>
              or drag and drop files
            </p>
          </button>
        }
      />
      {inputs.files?.length > 0 && (
        <ul className="grid gap-1 list-disc my-4">
          {inputs.files?.map((file: any, fileIndex: number) => {
            return (
              <li
                key={fileIndex}
                className="flex flex-col flex-grow gap-5 w-full"
              >
                <article className="flex items-center justify-between gap-5 w-full">
                  <header className="flex flex-wrap items-center gap-1 w-3/5">
                    <h4>
                      {fileIndex + 1}. {file.name}
                    </h4>
                    <span>({convertBytes(file.size)})</span>
                  </header>
                  <article className="flex items-center gap-7 justify-content-end">
                    <DocumentTypeFilter
                      label="Type"
                      fileIndex={fileIndex}
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    {["Audit Reports", "Privacy Documents"].includes(
                      inputs.document_types[fileIndex]
                    ) && (
                      <SelectDocFrameworkFilter
                        label="Framework"
                        fileIndex={fileIndex}
                        inputs={inputs}
                        setInputs={setInputs}
                        type={inputs.document_types[fileIndex]}
                      />
                    )}
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="cursor-pointer red-button"
                      onClick={(e) =>
                        setInputs({
                          ...inputs,
                          files: [
                            ...inputs.files.filter(
                              (_: any, curFileIndex: number) =>
                                curFileIndex !== fileIndex
                            ),
                          ],
                          document_types: [
                            ...inputs.document_types.filter(
                              (_: any, curFileIndex: number) =>
                                curFileIndex !== fileIndex
                            ),
                          ],
                          frameworks: [
                            ...inputs.frameworks.filter(
                              (_: any, curFileIndex: number) =>
                                curFileIndex !== fileIndex
                            ),
                          ],
                          to_extracts: [
                            ...inputs.to_extracts.filter(
                              (_: any, curFileIndex: number) =>
                                curFileIndex !== fileIndex
                            ),
                          ],
                        })
                      }
                    />
                  </article>
                </article>
                {inputs.document_types[fileIndex] ===
                  "Questionnaire Responses" && (
                  <UploadVendorResponses
                    file={file}
                    fileIndex={fileIndex}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </article>
  );
};

export default MultipleVendorFileInput;

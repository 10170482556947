import React, { Fragment, useEffect, useState } from "react";
import NewQuestionSet from "./NewGlobalQuestionSet";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { KeyStringVal } from "src/types/general";
import { pageSize } from "src/constants/general";
import Response from "./Response";
import {
  GetGlobalQuestions,
  ReorderGlobalQuestion,
} from "src/services/third-party-risk/questions/global-questions";
import AskGlobalQuestion from "./AskGlobalQuestion";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import CreateManualSet from "./CreateManualSet";
import RemoveQuestions from "./RemoveQuestions";
import { sortNumericData } from "../../../../utils/general";
import AllTags from "./AllTags";

const GlobalQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
  });
  const [list, setList] = useState([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const { data: globalQuestions, status: globalQuestionsStatus } =
    GetGlobalQuestions(pageNumber, selectedTags);
  const reorderQuestion = ReorderGlobalQuestion();

  const totalCount = globalQuestions?.pager?.total_results || 0;
  const totalPages = globalQuestions?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setList(arrayMove(list, oldIdx, newIdx));
      reorderQuestion.mutate({
        questionID: e.active.id,
        order_key: newIdx - oldIdx,
      });
    }
  };

  useEffect(() => {
    if (globalQuestions?.data)
      setList(sortNumericData(globalQuestions.data, "order_key", "asc"));
  }, [globalQuestions]);

  return (
    <section className="flex flex-col flex-grow gap-5">
      {globalQuestions?.data.length > 0 && (
        <article className="flex items-center gap-5 mx-auto">
          <NewQuestionSet />
          <span>or</span>
          <AskGlobalQuestion />
        </article>
      )}
      <AllTags selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
      {globalQuestionsStatus === "loading" ? (
        <Loader />
      ) : globalQuestions?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5">
          <article className="flex items-center justify-between gap-10">
            <article className="flex items-center gap-10">
              <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
                <button
                  disabled={
                    selectedQuestions.length === globalQuestions.data.length
                  }
                  className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                  onClick={() =>
                    setSelectedQuestions(
                      globalQuestions.data.reduce(
                        (pV: string[], cV: KeyStringVal) => [
                          ...pV,
                          cV.generated_id,
                        ],
                        []
                      )
                    )
                  }
                >
                  Select All
                </button>
                <button
                  disabled={selectedQuestions.length === 0}
                  className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                  onClick={() => setSelectedQuestions([])}
                >
                  Deselect All
                </button>
              </article>
              {selectedQuestions.length > 0 && (
                <>
                  <CreateManualSet
                    inputs={inputs}
                    setInputs={setInputs}
                    selectedQuestions={selectedQuestions}
                  />
                  <span>or</span>
                  <RemoveQuestions
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                  />
                </>
              )}
            </article>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </article>
          <DndContext
            sensors={sensors}
            onDragStart={(e) =>
              setActiveItem(
                list.find(
                  (item: KeyStringVal) => item.generated_id === e.active.id
                )
              )
            }
            onDragEnd={reorderQuestionsList}
          >
            <ul className="flex flex-col flex-grow gap-5 pb-20">
              <SortableContext items={list}>
                {list.map((qa: KeyStringVal, index: number) => {
                  return (
                    <Response
                      key={index}
                      qa={qa}
                      selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions}
                    />
                  );
                })}
              </SortableContext>
            </ul>
            <DragOverlay
              dropAnimation={{
                duration: 150,
                easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
              }}
            >
              {activeItem ? (
                <Response
                  qa={activeItem}
                  selectedQuestions={selectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="question sets placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Global Questions</h4>
            <h4>No questions available</h4>
            <NewQuestionSet />
            <span>or</span>
            <AskGlobalQuestion />
          </article>
        </section>
      )}
    </section>
  );
};

export default GlobalQuestions;
